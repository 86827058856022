<template>
  <section id="dashboard-blumar">

    <b-row>
      <b-col>
        <BrunaForm
          ref="skusRef"
          :fields="this.sku.fields"
          :card="this.sku.card"
          @change="skuChange"
        >
          <template v-slot:footer>
            <b-button
              variant="primary"
              class="mt-4 float-right"
              @click="addNewSku"
              >Save</b-button
            >
          </template>
        </BrunaForm>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ag-grid-table
        ref="grid-historico-skus"
      :configUrl="historicoSkusConfig"
      :dataUrl="historicoSkusData"
      :editUrl="historicoSkusEdit"
      @selectionChanged="selectionChanged"
      rowSelection="single"
    ></ag-grid-table>


      </b-col>
    </b-row>



  </section>
</template>
<script>
import
{
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";


import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";



import useApiServices from '@/services/useApiServices.js';


export default {
  data()
  {
    return {

      historicoSkusConfig: useApiServices.historicoSkusConfig,
      historicoSkusData: useApiServices.historicoSkusData,
      historicoSkusEdit: useApiServices.historicoSkusEdit,


      sku: {
        card: {
          title: "Agregar nuevo sku",
          subtitle: "",
          tooltip: ""
        },
        fields: [


          {
            label: "Código",
            type: "input",
            id: 'codigo',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Nombre",
            type: "input",
            id: 'nombre',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "Familia",
            type: "input",
            id: 'familia',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Producto",
            type: "input",
            id: 'producto',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Rendimientos (%) Kg PF / Kg Wfe",
            type: "input",
            id: 'Rendimientos (%) Kg PF / Kg Wfe',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Costo Producción Planta 1 USD/kg PF",
            type: "number",
            id: 'Costo Producción Planta 1 USD/kg PF',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Calibre 0-2",
            type: "select",
            id: '0-2',
            rules: 'required|numeric2',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 1, text: "Si" },
              { value: 0, text: "No" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "Calibre 2-3",
            type: "select",
            id: '2-3',
            rules: 'required|numeric2',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 1, text: "Si" },
              { value: 0, text: "No" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },



          {
            label: "Calibre 3-4",
            type: "select",
            id: '3-4',
            rules: 'required|numeric2',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 1, text: "Si" },
              { value: 0, text: "No" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "Calibre 4-5",
            type: "select",
            id: '4-5',
            rules: 'required|numeric2',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 1, text: "Si" },
              { value: 0, text: "No" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Calibre 5-6",
            type: "select",
            id: '5-6',
            rules: 'required|numeric2',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 1, text: "Si" },
              { value: 0, text: "No" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },          


          {
            label: "Calibre 6-up",
            type: "select",
            id: '6-up',
            rules: 'required|numeric2',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 1, text: "Si" },
              { value: 0, text: "No" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },            

       

        ]
      },








    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,
    BrunaForm,
    ToastificationContent
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted()
  {

  },

  methods: {

    skuChange(field)
    {
      console.log({field})
    
    },

    selectionChanged(rows){

      console.log(rows)

    },

    addNewSku()
    {
      let data = this.sku.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})

      Promise.all([
        this.$refs.skusRef.validate(),
      ]).then(([skuValid]) =>
      {
        if (skuValid)
        {

          useApiServices.postSkuCreate(data, this)
            .then((response) =>
            {

              this.$refs['grid-historico-skus'].refreshRows()

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Carga Exitosa`,
                  icon: "UploadIcon",
                  variant: "success",
                  html: `Se ha guardado el nuevo registro exitosamente.`,
                },
              });

              //this.sku.fields.forEach(field => { field.value = null })

            })
            .catch((error) =>
            {

              let message = error.response.data.message;

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ocurrio un Error`,
                  icon: "UploadIcon",
                  variant: "danger",
                  html: message,
                },
              });

            });



        }
      });




    }


  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

